import styled from 'styled-components';

export const Content = styled.div`
    text-align: ${(props) => (props.align ? props.align : 'center')};
    padding: ${(props) => (props.padding ? props.padding.replace('px', '') : '40')}px;
`;

export const Spacing = styled.div`
  margin-${(props) => props.direction || 'top'}: ${(props) => (props.margin ? props.margin.replace('px', '') : '40')}px;
`;

export const MaxWidthWrapper = styled.div`
    max-width: ${(props) => (props.maxWidth ? props.maxWidth.replace('px', '') : '300')}px;
    margin: auto;
`;

export const GuestyLogo = styled.img`
    height: 50px;
    cursor: pointer;
    position: relative;
    top: 15px;
    ${(props) => (props.selected ? '' : 'filter: grayscale(100%); -webkit-filter: grayscale(100%);')}
`;

export const RentalsUnitedLogo = styled.img`
    height: 40px;
    cursor: pointer;
    position: relative;
    top: 15px;
    ${(props) => (props.selected ? '' : 'filter: grayscale(100%); -webkit-filter: grayscale(100%);')}
`;

export const Image = styled.img`
    height: ${(props) => (props.height ? props.height : '50px')};
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '20px')};
    display: ${(props) => (props.hidden ? 'none' : 'inline-block')};
`;

export const PortalBackgroundBanner = styled.div`
    background-color: ${(props) => (props.color ? props.color : '#013D8B')};
    height: ${(props) => (props.height ? props.height : '300px')};
    width: 100%;
    z-index: ${(props) => (props.zIndex ? props.zIndex : '0')};
    position: fixed;
    left: 0px;
    top: 0px;
`;
